import { useEffect, useState } from "react";
import { AnalyticsBrowser } from "@june-so/analytics-next";

export function useAnalytics() {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();

  useEffect(() => {
    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({
        writeKey: "<key>",
      });
      setAnalytics(response as AnalyticsBrowser);
    };
    loadAnalytics();
  }, []);

  return analytics;
}
