import data from '../../../config/config.json';
import { Center, Text } from '@chakra-ui/react';

export const PageNotFoundPage = () => {
  return (
    <Center width={'100%'} height={'100vh'}>
      <Text fontSize={'4xl'} fontWeight={'extrabold'}>
        {data[404]}
      </Text>
    </Center>
  );
};
