import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

export const EmptyLayout = () => {
  return (
    <Flex
      className="emptyLayout"
      direction="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      height="100vh"
    >
      <Outlet />
    </Flex>
  );
};
